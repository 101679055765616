import {
  ProductPartType,
  CartItemType,
  CustomerSetType,
  ProductSubLine,
  PropName,
} from 'types/Common'
import {
  isUniversalDevice,
  getPartPropName,
  getPartSize,
} from 'utils/PartUtils'
import { db } from 'constants/database'
import {
  saveActiveCustomerSetIdVar,
  saveCustomerSetsVar,
  storedActiveCustomerSetIdVar,
  storedCustomerSetsVar,
} from 'apollo/storage'

import { makeVar } from '@apollo/client'

/*
  null -> initial state (closed)
  false -> user forced closing (closed)
  true -> visible
*/
export const isCustomerSetHintVisibleVar = makeVar<boolean | null>(null)

export const configuratorInitSubLineId = makeVar<string>(null)
export const configuratorInitSubLineSlug = makeVar<string>(null)
export const configuratorInitLineSlug = makeVar<string>(null)

export const pdfVisualizerLayersVar = makeVar([])

export const activeCustomerSetIdVar = makeVar<string>(
  storedActiveCustomerSetIdVar() || 'default_set',
)

export const defaultVisualizer = {
  selectedParts: {
    device: null,
    frame: null,
    decorativeFrame: null,
  },
  selectedPreviewOptions: {
    background: null,
  },
}

export const defaultCart = {
  decorativeAutoAdd: true,
  devices: [],
  frames: [],
  decorativeFrames: [],
  constructionFrames: [],
}

export const createCustomerSetObj = (id, name) => ({
  id,
  name,
  init: true,
  productLineId: null,
  productSubLineSlug: null,
  productLineSlug: null,
  visualizer: defaultVisualizer,
  cart: defaultCart,
})

export const customerSetsVar = makeVar<CustomerSetType[]>(
  storedCustomerSetsVar() || [createCustomerSetObj('default_set', 'Sestava 1')],
)

//
// CUSTOMER SET
//
export const getCustomerSet = (customerSetId) => {
  const customerSets = customerSetsVar()
  return customerSets.find((cs) => cs.id === customerSetId)
}

export const getActiveCustomerSet = () => {
  const customerSets = customerSetsVar()
  const activeCustomerSetId = activeCustomerSetIdVar()
  const activeCustomerSet = getCustomerSet(activeCustomerSetId)
  return activeCustomerSet ? activeCustomerSet : customerSets[0]
}

export const setActiveCustomerSet = (customerSetId) => {
  activeCustomerSetIdVar(customerSetId)
  saveActiveCustomerSetIdVar(customerSetId)
}

export const updateCustomerSet = (customerSetId, newCustomerSet) => {
  const customerSets = customerSetsVar()

  const updatedCustomerSets = []
  customerSets.forEach((cs) => {
    if (cs.id === customerSetId) {
      let customerSet = { ...cs }
      const oldProductSubLineSlug = cs.productSubLineSlug
      const newProductSubLineSlug = newCustomerSet.productSubLineSlug
      if (oldProductSubLineSlug !== newProductSubLineSlug) {
        //
        // RESETS ON SELECTION CHANGE
        //
      }

      customerSet = { ...customerSet, init: false, ...newCustomerSet }
      updatedCustomerSets.push(customerSet)
    } else {
      updatedCustomerSets.push({ ...cs })
    }
  })
  customerSetsVar(updatedCustomerSets)
  saveCustomerSetsVar(updatedCustomerSets)
}

export const addCustomerSet = (newCustomerSet) => {
  const newCustomerSets = [...customerSetsVar(), newCustomerSet]
  customerSetsVar(newCustomerSets)
  setActiveCustomerSet(newCustomerSet.id)
  saveCustomerSetsVar(newCustomerSets)
}

export const removeCustomerSet = (customerSetId) => {
  const filteredCustomerSet = customerSetsVar().filter((cs) => {
    return cs.id !== customerSetId
  })
  customerSetsVar(filteredCustomerSet)
  saveCustomerSetsVar(filteredCustomerSet)
}

//
// VISUALIZER PRICE
//
export const getVisualizerPrice = () => {
  const priceArray = Object.values(
    getActiveCustomerSet()?.visualizer?.selectedParts,
  )
    ?.filter((v: any) => v?.Price)
    ?.map((v: any) => v?.Price)

  return priceArray?.length > 0
    ? priceArray?.reduce((a: number, b: number) => a + b)
    : 0
}

//
// CART
//
export const setCartProperty = (options: {
  propValue: any
  propName: PropName | 'decorativeAutoAdd'
  customerSet: any
}) => {
  const newCart = {}
  Object.entries(options.customerSet.cart).forEach((entry) => {
    const propName = entry[0]
    const cartItems = entry[1]
    const isUniversal =
      propName === 'devices' && options.propName === 'universals'

    if (propName === options.propName || isUniversal) {
      newCart[propName] = options.propValue
    } else {
      newCart[propName] = cartItems
    }
  })
  updateCustomerSet(options.customerSet.id, {
    ...options.customerSet,
    cart: newCart,
  })
}

const setCartPropertyWithCoupled = (options: {
  productSubLine?: ProductSubLine
  propValue: CartItemType[]
  propName: PropName
  customerSet: CustomerSetType
  addedItem: CartItemType
  coupledItems?: any[]
  isRemove?: boolean
  newCount?: number
}) => {
  setCartProperty({
    customerSet: options.customerSet,
    propName: options.propName,
    propValue: options.propValue,
  })

  const cartCountAfter =
    options.customerSet.productLineId === 'Mertens' ||
    options.customerSet.productLineSlug === 'merten'
      ? getCartCountsWithPartSizeForAddedItem(
          options.customerSet.id,
          options.coupledItems,
        )
      : getCartCountsWithPartSize(options.customerSet.id)

  // COUPLED PARTS
  if (options.propName === 'devices' && options.coupledItems?.length) {
    options.coupledItems.forEach((coupledItem) => {
      const coupledCount =
        getPartSize(options.addedItem?.product) === '0'
          ? options.isRemove
            ? -1
            : 1
          : Math.ceil(cartCountAfter.devices)

      autoAddRemoveCoupled(
        options.customerSet.id,
        coupledCount,
        coupledItem,
        options.productSubLine,
      )
    })
  }
}

const autoAddRemoveCoupled = (
  customerSetId,
  count,
  coupledPart,
  productSubLine,
) => {
  const customerSet = customerSetId
    ? getCustomerSet(customerSetId)
    : getActiveCustomerSet()

  if (coupledPart) {
    // TODO: Fix this to return already correct string
    const partPropName = getPartPropName(coupledPart)
    let cartPropName = partPropName ? `${partPropName}s` : 'constructionFrames'

    if (productSubLine?.ProductLine?.Name === 'Merten') {
      let checkProductSubLine = coupledPart.ProductSubLines.filter(
        (part) => part.Name === productSubLine.Name,
      )
      if (checkProductSubLine.length === 0) {
        count = 0
      }
    }

    if (count > 0) {
      const coupledItem = {
        groupLabel: cartPropName === 'frames' ? 'Rámečky' : null,
        product: {
          ...coupledPart,
          tableFullName: coupledPart.Name,
          tableName: coupledPart.Type?.Name,
          tableDescription: coupledPart.Type?.Description,
          tableIcon: coupledPart.Type?.Icon,
          tableIconVertical: false,
          tablePriceNote:
            cartPropName === 'frames'
              ? 'Přidán automaticky'
              : 'Přidán automaticky ke každému přístroji',
        },
      }

      addToCart({
        cartItem: coupledItem,
        propName: cartPropName as PropName,
        customerSetId: customerSet.id,
        coupledItems: [],
        newCount: count,
      })
    } else if (count < 0) {
      removeFromCart({
        productId: coupledPart?.id,
        productPartNumber: coupledPart?.PartNumber,
        propName: cartPropName as PropName,
        customerSetId: customerSet.id,
        coupledItems: [],
      })
    } else if (count === 0) {
      removeFromCart({
        productId: coupledPart?.id,
        productPartNumber: coupledPart?.PartNumber,
        propName: cartPropName as PropName,
        customerSetId: customerSet.id,
        coupledItems: [],
        completeRemoval: true,
      })
    }
  }
}

export const manualCartChange = (options: {
  productSubLine?: ProductSubLine
  cartItem: CartItemType
  propName: PropName
  initCount?: number
  customerSetId?: string
  coupledItems: any[]
  newCount: string
}) => {
  const customerSet = options.customerSetId
    ? getCustomerSet(options.customerSetId)
    : getActiveCustomerSet()
  const parsedNewCount = parseInt(options.newCount)

  const propName =
    options.propName === 'universals' ? 'devices' : options.propName

  customerSet.cart[propName]?.forEach((storedCartItem) => {
    if (
      (storedCartItem?.product?.id &&
        storedCartItem?.product?.id === options.cartItem?.product?.id) ||
      (!storedCartItem?.product?.id &&
        storedCartItem?.product?.PartNumber ===
          options.cartItem?.product?.PartNumber)
    ) {
      removeFromCartCompletely({
        productId: options.cartItem.product.id,
        propName: options.propName,
        customerSetId: options.customerSetId,
        coupledItems: options.coupledItems,
      })
      if (parsedNewCount > 0) {
        addToCart({
          cartItem: options.cartItem,
          propName: options.propName,
          customerSetId: customerSet.id,
          coupledItems: options.coupledItems,
          newCount: parsedNewCount,
          productSubLine: options.productSubLine,
        })
      }
    }
  })
}

export const addToCart = (options: {
  productSubLine?: ProductSubLine
  cartItem: CartItemType
  propName: PropName
  initCount?: number
  customerSetId?: string
  coupledItems: any[]
  newCount?: number
}) => {
  const customerSet = options.customerSetId
    ? getCustomerSet(options.customerSetId)
    : getActiveCustomerSet()

  const newCountHasValue =
    options.newCount !== null && options.newCount !== undefined
  const newCartItems: CartItemType[] = []
  let alreadyInCart = false

  const propName =
    options.propName === 'universals' ? 'devices' : options.propName

  customerSet.cart[propName]?.forEach((storedCartItem) => {
    if (
      (storedCartItem?.product?.id &&
        storedCartItem?.product?.id === options.cartItem?.product?.id) ||
      (!storedCartItem?.product?.id &&
        storedCartItem?.product?.PartNumber ===
          options.cartItem?.product?.PartNumber)
    ) {
      alreadyInCart = true
      newCartItems.push({
        ...storedCartItem,
        count: newCountHasValue ? options.newCount : storedCartItem.count + 1,
      })
    } else {
      newCartItems.push(storedCartItem)
    }
  })

  if (!alreadyInCart) {
    newCartItems.push({
      ...options.cartItem,
      count: options.newCount
        ? options.newCount
        : options.initCount
        ? options.initCount
        : 1,
    })
  }

  setCartPropertyWithCoupled({
    productSubLine: options.productSubLine,
    customerSet,
    propName: options.propName,
    propValue: newCartItems,
    addedItem: options.cartItem,
    coupledItems: options.coupledItems,
    newCount: options.newCount,
  })
}

export const swapInCart = (options: {
  oldProduct: ProductPartType
  newProduct: ProductPartType
  propName: PropName
  customerSetId?: string
}) => {
  const customerSet = options.customerSetId
    ? getCustomerSet(options.customerSetId)
    : getActiveCustomerSet()

  const newCartItems = []
  customerSet.cart[options.propName].forEach((storedCartItem) => {
    if (storedCartItem?.product?.id === options.oldProduct?.id) {
      newCartItems.push({
        ...storedCartItem,
        product: options.newProduct,
      })
    } else {
      newCartItems.push(storedCartItem)
    }
  })

  setCartProperty({
    customerSet,
    propName: options.propName,
    propValue: newCartItems,
  })
}

export const removeFromCart = (options: {
  productId: string
  productPartNumber: string
  propName: PropName
  customerSetId?: string
  coupledItems: any[]
  completeRemoval?: boolean
}) => {
  const customerSet = options.customerSetId
    ? getCustomerSet(options.customerSetId)
    : getActiveCustomerSet()

  const newCartItems = []

  let removedCartItem = null

  const propName =
    options.propName === 'universals' ? 'devices' : options.propName
  customerSet.cart[propName]?.forEach((storedCartItem) => {
    if (
      (storedCartItem.product?.id &&
        storedCartItem.product?.id === options.productId) ||
      (!storedCartItem.product?.id &&
        storedCartItem?.product?.PartNumber === options.productPartNumber)
    ) {
      removedCartItem = storedCartItem
      if (storedCartItem.count > 1 && !options.completeRemoval) {
        newCartItems.push({
          ...storedCartItem,
          count: storedCartItem.count - 1,
        })
      }
    } else {
      newCartItems.push(storedCartItem)
    }
  })

  setCartPropertyWithCoupled({
    customerSet,
    propName: options.propName,
    propValue: newCartItems,
    addedItem: removedCartItem,
    coupledItems: options.coupledItems,
    isRemove: true,
  })
}

export const removeFromCartCompletely = (options: {
  productId: string
  propName: PropName
  customerSetId?: string
  coupledItems: any[]
}) => {
  const customerSet = options.customerSetId
    ? getCustomerSet(options.customerSetId)
    : getActiveCustomerSet()

  let removeFromCartOptions = { ...options, completeRemoval: true }

  // @ts-ignore
  removeFromCart(removeFromCartOptions)
}

export const setDecorativeAutoAdd = (options: {
  value: boolean
  customerSetId?: string
}) => {
  const customerSet = options.customerSetId
    ? getCustomerSet(options.customerSetId)
    : getActiveCustomerSet()

  setCartProperty({
    customerSet,
    propName: 'decorativeAutoAdd',
    propValue: options.value,
  })
}

export const getCartCounts = (customerSetId) => {
  /**
   * Return individual product counts from cart
   */
  const itemsWithCount = getCartItemsWithCount(customerSetId)
  const finalCounts: any = {}

  Object.entries(itemsWithCount).forEach((entry) => {
    const propName = entry[0]
    const propItems = entry[1]
    const propItemsValues = propItems ? Object.values(propItems) : null

    let count = 0
    if (propItemsValues?.length) {
      count = propItemsValues.reduce((a, b) => a + b)
    }
    finalCounts[propName] = count
  })
  return finalCounts
}

export const getCartCountsWithPartSizeForAddedItem = (
  customerSetId: string,
  coupledItems?: any[],
) => {
  /**
   * Return individual product counts from cart
   */
  const itemsWithCount = getCartItemsWithCount(customerSetId, {
    includePartSize: true,
  })
  const finalCounts: any = {}

  Object.entries(itemsWithCount).forEach((entry) => {
    const propName = entry[0]
    const propItems = entry[1]
    const propItemsValues = propItems ? Object.values(propItems) : null

    let count = 0
    if (propItemsValues?.length) {
      const coupledIds = coupledItems.map((item) => item.id)
      console.log('propItemsValues', propItemsValues)
      count = propItemsValues
        .map((v) => {
          return v.coupledWith.find((couple) => coupledIds.includes(couple.id))
            ? v.count * v.partSize
            : 0
        })
        .reduce((a, b) => a + b)
    }
    finalCounts[propName] = count
  })

  return finalCounts
}

export const getCartCountsWithPartSize = (customerSetId: string) => {
  /**
   * Return individual product counts from cart
   */
  const itemsWithCount = getCartItemsWithCount(customerSetId, {
    includePartSize: true,
  })
  const finalCounts: any = {}

  Object.entries(itemsWithCount).forEach((entry) => {
    const propName = entry[0]
    const propItems = entry[1]
    const propItemsValues = propItems ? Object.values(propItems) : null

    let count = 0
    if (propItemsValues?.length) {
      count = propItemsValues
        .map((v) => v.count * v.partSize)
        .reduce((a, b) => a + b)
    }
    finalCounts[propName] = count
  })

  return finalCounts
}

export const getCartItemsWithCount = (
  customerSetId,
  options = { includePartSize: false },
) => {
  /**
   * Returns items in cart in { devices: {id1: count1, id2: count2} } object format
   */
  const activeCustomerSet = getCustomerSet(customerSetId)
  const itemsInCart: any = {}

  if (!activeCustomerSet?.cart) return itemsInCart

  if (!activeCustomerSet?.cart) return itemsInCart

  if (!activeCustomerSet?.cart) {
    return {}
  }

  Object.keys(activeCustomerSet?.cart).forEach((propName) => {
    if (
      ['decorativeFrames', 'frames', 'devices', 'constructionFrames'].includes(
        propName,
      )
    ) {
      itemsInCart[propName] = {}
      activeCustomerSet.cart[propName].forEach((item) => {
        // do not count coupled parts for universal devices into cart total
        const isCoupledFromUniversalDevice =
          item.product?.Type?.Name === db.TYPE_COVER_DEVICE_FRAME

        if (item.product && !isCoupledFromUniversalDevice) {
          if (options.includePartSize) {
            const partSize = getPartSize(item.product)
            itemsInCart[propName][
              item.product.PartNumber +
                (propName === 'devices'
                  ? '_' + item.product.Type?.id + '_' + item.product.SubType?.id
                  : '')
            ] = {
              count: item.count,
              partSize: partSize ? partSize : 1,
              coupledWith: item.product.CoupledWith,
            }
          } else {
            itemsInCart[propName][
              item.product.PartNumber +
                (propName === 'devices'
                  ? '_' + item.product.Type?.id + '_' + item.product.SubType?.id
                  : '')
            ] = item.count
          }
        }
      })
    }
  })

  return itemsInCart
}

export const getCartCountCustomerSet = (customerSetId) => {
  /**
   * Return sum of all product counts in cart
   */
  const cartCounts = getCartCounts(customerSetId)
  return Object.values(cartCounts).reduce(
    (a: any, b: any) => parseInt(a) + parseInt(b),
  )
}

export const getCartCountTotal = () => {
  /**
   * Return sum of all product counts in cart
   */
  let count = 0
  const customerSets = customerSetsVar()
  customerSets.forEach((customerSet) => {
    const customerSetCount = getCartCountCustomerSet(customerSet.id)
    if (typeof customerSetCount === 'number') {
      count = count + customerSetCount
    }
  })
  return count
}

export const getCustomerSetPriceTotal = (customerSet) => {
  const cartItems: any = [
    ...Object.values(customerSet?.cart?.frames || {}),
    ...Object.values(customerSet?.cart?.devices || {}),
    ...Object.values(customerSet?.cart?.decorativeFrames || {}),
    ...Object.values(customerSet?.cart?.constructionFrames || {}),
  ]

  const total = cartItems.reduce((acc, cartItem) => {
    const price = cartItem.product?.Price * cartItem.count || 0
    return acc + price
  }, 0)

  return total
}

export const getCartPriceTotal = () => {
  /**
   * Return sum of all prices in cart
   */
  let total = 0
  const customerSets = customerSetsVar()
  customerSets.forEach((customerSet) => {
    total += getCustomerSetPriceTotal(customerSet)
  })
  return total
}

const getCartPropProducts = (cart, wholeItem = false) => {
  const parts = []
  if (cart) {
    ;['frames', 'decorativeFrames', 'devices', 'constructionFrames'].forEach(
      (propName) => {
        if (cart[propName]) {
          cart[propName].forEach((cartItem) => {
            if (wholeItem) {
              parts.push(cartItem)
            } else {
              parts.push(cartItem.product)
            }
          })
        }
      },
    )
  }
  return parts
}

export const getAllPartsNumberWithCountFromCart = () => {
  const customerSets = customerSetsVar()
  const parts = []
  customerSets?.forEach((customerSet) => {
    parts.push(...getCartPropProducts(customerSet.cart, true))
  })
  return parts
}

export const getAllPartsFromCart = () => {
  const customerSets = customerSetsVar()
  const parts = []
  customerSets?.forEach((customerSet) => {
    parts.push(...getCartPropProducts(customerSet.cart))
  })
  return parts
}

export const getAllPartNumbersFromCart = () => {
  const parts = getAllPartsFromCart()
  return parts?.length ? parts.map((part) => part.PartNumber) : []
}
